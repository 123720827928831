/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. Licensed under a proprietary license.
 * See the License.txt file for more information. You may not use this file
 * except in compliance with the proprietary license.
 */

import {put} from 'request';

export async function updateTelemetry(enabled: boolean): Promise<Response> {
  return await put('api/settings', {metadataTelemetryEnabled: enabled});
}
